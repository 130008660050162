import {IMAGE_OPTIONS, TRANSPORT_OPTIONS, YES_NO_OPTIONS} from "@/libs/const";
import {debounce, downloadExcelFile, formatNumber, getUrlParameter, isMobile} from "@/libs/helpers";
import {httpClient} from "@/libs/http";
import moment from "moment/moment";

export default {
    data: () => ({
        page: 1,
        totalPage: 1,
        items: [],
        viewOption: 'view0',
        filters: {},
        imageOptions: [...IMAGE_OPTIONS],
        transportOptions: [...TRANSPORT_OPTIONS],
        yesNoOptions: [...YES_NO_OPTIONS],
        isLoading: false,
        propertyDialog: false,
        costDialog: false,
        uidDialog: false,
        uidSku: null,
        updatingItem: {},
        sum: {},
        qrScanDialog: false,
        qrScanType: null,
    }),
    watch: {
        page() {
            this.getList()
        },
    },
    computed: {
        viewOptions() {
            const otps = [
                {
                    text: this.$t('views.compact'),
                    value: 'view0'
                }
            ];
            if (this.checkPermission(['goods_report_volume'])) {
                otps.push({
                    text: this.$t('views.compact_volume'),
                    value: 'view1'
                })
            }
            if (this.checkPermission(['goods_report_size'])) {
                otps.push({
                    text: this.$t('views.compact_size'),
                    value: 'view2'
                })
            }
            if (this.checkPermission(['goods_report_cost'])) {
                otps.push({
                    text: this.$t('views.compact_price'),
                    value: 'view3'
                })
            }
            if (this.checkPermission(['goods_report_full'])) {
                otps.push({
                    text: this.$t('views.full'),
                    value: 'view4'
                })
            }
            return otps
        }
    },
    methods: {
        downloadExcelFile,
        formatNumber,
        getUrlParameter,
        isMobile,
        onDbClick(sku) {
            if (this.checkPermission(['goods_uid_manager'])) {
                this.uidSku = sku
                this.showUidDialog()
            }
        },
        showUidDialog() {
            this.uidDialog = true
        },
        hideUidDialog() {
            this.uidDialog = false
            this.uidSku = null
        },
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this.filters = {...this.filters, [filter.name]: filter.value}
            this.getList()
        },
        setViewOption(mode) {
            this.viewOption = mode
        },
        onFilterChange(filter) {
            this.filters = {...this.filters, [filter.name]: filter.value}
            /*if (filter.name === 'sku') {
                let valueSpl = filter.value && filter.value.split('@') || ['']
                this.filters = {...this.filters, [filter.name]: valueSpl[0]}
            } else {
                this.filters = {...this.filters, [filter.name]: filter.value}
            }*/
        },
        onSortChange(sort) {
            this.filters = {...this.filters, ...sort}
        },
        getList: debounce(function() {
            let filters = {...this.filters}
            if (filters.sku) {
                let valueSpl = filters.sku.split('@') || ['']
                filters = {...filters, sku: valueSpl[0]}
            }
            if (filters.keywords) {
                let valueSpl = filters.keywords.split('@') || ['']
                filters = {...filters, keywords: valueSpl[0]}
            }
            httpClient.post('/goods/v1/report', {...filters, page: this.page, viewMode: this.viewOption}).then(({data}) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = {...data.sum}
                if (this.isMobile()) {
                    this.filters = {...this.filters, keywords: null, storage_location: null}
                }
            })
        }, 500),
        async reportExport() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.waiting_file_download'))
                return false
            }
            this.isLoading = true
            const filename = 'hang-hoa.xlsx'

            try {
                await this.downloadExcelFile('/goods/v1/report-export', {...this.filters, viewMode: this.viewOption}, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },
        defaultDate() {
            return [
                moment().subtract(6, 'months').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ]
        },
        showPropertyDialog(item) {
            this.propertyDialog = true
            this.updatingItem = {...item}
        },
        hidePropertyDialog() {
            this.propertyDialog = false
            this.updatingItem = {}
        },
        showCostDialog(item) {
            this.costDialog = true
            this.updatingItem = {...item}
        },
        hideCostDialog() {
            this.costDialog = false
            this.updatingItem = {}
        },
        getStorageLocation(locations) {
            if (!locations) {
                return ''
            }
            const locationSplit = locations.split(';')
            return locationSplit.join('<br>')
        }
    },
    mounted() {
        const sku = this.getUrlParameter('sku')
        const customer_goods_barcode = this.getUrlParameter('barcode')
        if (this.isMobile()) {
            if (sku || customer_goods_barcode) {
                this.filters = {...this.filters, keywords: (sku || customer_goods_barcode)}
                this.getList()
            }
        } else {
            if (sku) {
                this.filters = {...this.filters, sku}
            }
            if (customer_goods_barcode) {
                this.filters = {...this.filters, customer_goods_barcode}
            }
            this.getList()
        }
    }
}
